import React from 'react'
import styled from 'styled-components'
import { Link, GatsbyLinkProps } from 'gatsby'
import { Text, TextProps } from '../../../../../atoms'

export const CategoryLinkElement = styled(Link)<GatsbyLinkProps<any>>`
  position: relative;
`

export const CategoryLinkText: React.FC<TextProps> = styled(Text)<TextProps>`
  ${CategoryLinkElement}.active & {
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  ${CategoryLinkElement}:hover & {
    color: ${({ theme }) => theme.colors.darkBlue};
  }
`
