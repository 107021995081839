import React from 'react'
// Utils
import { SECTION_BOTTOM_PADDING, SECTION_TOP_PADDING } from '../../../utils/constants'
// Types
import { HubspotFormProps, TypeformFormProps } from '../../../types/blocks'
// Components
import { StaticImage } from 'gatsby-plugin-image'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { Box, Heading, Text } from '../../../atoms'
import HubspotForm from '../../commons/HubspotForm'
import TypeformForm from '../../commons/TypeformForm'

interface BlogHeroProps {
  headline: string
  subHeadline: string
  form: HubspotFormProps | TypeformFormProps
}

const BlogHero: React.FC<BlogHeroProps> = ({ headline, subHeadline, form }) => {
  return (
    <Box as={'section'} overflow={'hidden'}>
      <Grid>
        <Box position={'relative'} pt={SECTION_TOP_PADDING} pb={SECTION_BOTTOM_PADDING}>
          <Box display={['none', 'none', 'block']} position={'absolute'} top={0} left={-150} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../images/ellipse_tr.png'}
              alt={''}
              placeholder={'none'}
              width={255}
            />
          </Box>
          <Box display={['none', 'none', 'block']} position={'absolute'} bottom={0} right={0} zIndex={0}>
            <StaticImage
              formats={['auto']}
              draggable={false}
              src={'../../../images/blog_hero.png'}
              alt={''}
              placeholder={'none'}
              width={398}
            />
          </Box>
          <Box position={'relative'} zIndex={1}>
            <Row>
              <Col xs={12} md={8}>
                <Heading as={'h1'} type={'display'}>
                  {headline}
                </Heading>
                {!!subHeadline && (
                  <Box mt={[4, 5, 6]}>
                    <Text type={'large'}>{subHeadline}</Text>
                  </Box>
                )}
              </Col>
            </Row>
            {form && (
              <Box mt={[4, 5, 6]}>
                <Row>
                  <Col xs={12} md={6}>
                    {form.model.apiKey === 'hubspot_form' && (
                      <HubspotForm {...(form as HubspotFormProps)} forNewsletter />
                    )}
                    {form.model.apiKey === 'typeform_form' && (
                      <TypeformForm format='horizontal' {...(form as TypeformFormProps)} />
                    )}
                  </Col>
                </Row>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Box>
  )
}

export default BlogHero
