import React from 'react'
// Types
import { PageInfoProps } from '../../../../../types/pages'
// Hooks
import { useLocale } from '../../../../../hooks/useLocale'
// Components
import { CategoryLinkElement, CategoryLinkText } from './CategoryLink.styled'

interface CategoryLinkProps {
  name: string
  pageInfo: PageInfoProps
  partiallyActive?: boolean
}

const CategoryLink: React.FC<CategoryLinkProps> = ({ pageInfo, name, partiallyActive = true }) => {
  const { localeSlug } = useLocale()

  return (
    <CategoryLinkElement
      to={`/${localeSlug}${pageInfo.slug}`}
      activeClassName={'active'}
      partiallyActive={partiallyActive}
    >
      <CategoryLinkText type={'regular'} fontWeight={'ultraBold'}>
        {name}
      </CategoryLinkText>
    </CategoryLinkElement>
  )
}

export default CategoryLink
