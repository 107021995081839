import React from 'react'
// Types
import { PageInfoProps } from '../../../../types/pages'
import { CategoryProps } from '../../../../types/blog'
// Components
import { Flex, Box } from '../../../../atoms'
import CategoryLink from './CategoryLink'

interface CategoriesListProps {
  blogLandingPageInfo: PageInfoProps
  allLabel: string
  categories: CategoryProps[]
  isAll?: boolean
}

const CategoriesList: React.FC<CategoriesListProps> = ({ blogLandingPageInfo, allLabel, categories, isAll }) => {
  return (
    <Flex justifyContent={'flex-start'} alignItems={'center'} flexWrap={'wrap'} my={[6, 7, 8]}>
      <Box mr={[4, 4, 5]} my={1}>
        <CategoryLink name={allLabel} pageInfo={blogLandingPageInfo} partiallyActive={isAll} />
      </Box>
      {categories.map((c) => (
        <Box key={c.id} mr={[4, 4, 5]} my={1}>
          <CategoryLink {...c} />
        </Box>
      ))}
    </Flex>
  )
}

export default CategoriesList
